import React, { useEffect, useState, useRef } from "react";
import Modal from "./modal";
import ClipLoader from "react-spinners/ClipLoader";
import styled from "styled-components";
import { animated, useSpring } from "@react-spring/web";

const inViewPort = (el: any) => {
  const rect = el.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

  return vertInView && horInView;
};

const Img = (props: {
  src: string;
  largeSrc?: string;
  loadInstant?: boolean;
  width?: number;
  square?: boolean;
  style?: any;
  className?: string;
  onClick?: () => void;
}) => {
  const [loadedState, setLoaded] = useState(false);
  const [inViewState, setInView] = useState(props.loadInstant ? true : false);
  const [modal, setModal] = useState(false);
  const ref = useRef<HTMLImageElement>(null);

  const slideTransition = useSpring({
    config: {
      duration: 200,
    },
    transform: modal ? "translate3d(0,0,0)" : "translate3d(0,-25px,0)",
  });

  useEffect(() => {
    check();
  }, []);

  useEffect(() => {
    if (inViewState) {
      const img = new Image();
      img.src = props.src;
      if (props.width) {
        img.width = props.width;
      }
      img.onload = () => {
        resize();
        setLoaded(true);
        img.onload = null;
      };
    }

    window.addEventListener("resize", resize, false);
    window.addEventListener("scroll", check);
    return () => {
      window.removeEventListener("scroll", check);
      window.removeEventListener("resize", resize);
    };
  }, [inViewState, loadedState]);

  const resize = () => {
    if (props.square && ref.current) {
      const width = ref.current.width;
      ref.current.height = width;
    }
  };

  const check = () => {
    if (!loadedState || !inViewState) {
      setInView(inViewPort(ref.current));
    }
  };

  const onClick = () => {
    if (props.onClick) {
      return props.onClick();
    }
    setModal(!modal);
  };

  const close = () => {
    setModal(false);
  };

  if (typeof navigator !== "undefined" && !navigator.onLine) {
    return <div>You need to be online to view photos</div>;
  }
  if (!loadedState || !inViewState) {
    return (
      <div style={{ height: "80px" }} ref={ref}>
        <ClipLoader
          size={60} // or 150px
          color={"#c74339"}
          loading={true}
        />
      </div>
    );
  }

  return (
    <>
      <ImgTag
        ref={ref}
        src={props.src}
        width={props.width}
        height={props.width}
        style={props.style}
        alt=""
        onClick={onClick}
        className={props.className}
      />
      {modal && (
        <Modal>
          <div className="modal-content">
            <ImgWrap onClick={close}>
              <animated.div
                style={{
                  ...slideTransition,
                  height: "100vh",
                  width: "100vw",
                }}
              >
                <BigImg src={props.largeSrc || props.src} />
              </animated.div>
            </ImgWrap>
          </div>
        </Modal>
      )}
    </>
  );
};

const ImgWrap = styled.div`
  width: 100%;
  height: 100%;
  background-color: #1d1d1d;
`;

const BigImg = styled.img`
  max-width: 90vw;
  max-height: 90vh;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 15px solid #fff;
`;

export const ImgTag = styled.img`
  cursor: pointer;
  &.project-photo {
    width: 100%;
    margin: 0 auto 4em;
    display: block;
  }
`;

export default Img;
