import { gql } from '@apollo/client';

export const GET_POSTS_QUERY = gql`
  query GetPosts {
    getMediumPosts {
      title
      pubDate
      link
      category
      img
    }
    getSanityPosts {
      id
      title
      slug
      image
      ingress
      content
      publishedAt
      published
    }
  }
`;

export const GET_POST_QUERY = gql`
  query GetPost($slug: String) {
    getSanityPost(slug: $slug) {
      id
      title
      slug
      image
      ingress
      content
      publishedAt
      published
    }
  }
`;
