import React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import { animated } from "@react-spring/web";

import { Footer } from "./shared/footer";
import HelmetConfig from "./shared/helmet-config";

import { GET_POSTS_QUERY } from "../graphql/query";
import { useFadeIn } from "./shared/hooks/useFadeIn";
import PageHeader from "./shared/page-header";
import { ScrollDown } from "./shared/scrolldown";
import { FrontFullWidth } from "./shared/frontFullWidth";
import { useLatestImages } from "./shared/hooks/useLatestImages";
import Img from "./shared/img";
import { Logo } from "./shared/logo";

const Frontpage: React.FC = () => {
  const { data, loading, error } = useQuery(GET_POSTS_QUERY);
  const { getLatestN } = useLatestImages();
  const fade = useFadeIn();

  if (loading) {
    return null;
  }
  if (error) {
    return <div>Feil</div>;
  }

  const lastNine = getLatestN(12);
  return (
    <>
      <HelmetConfig
        title="Velkommen"
        description="Glenn Wedin - IT-konsulent"
      />
      <Front style={fade.fadeIn}>
        <PageHeader color="WHITE" />
        <Logo color="WHITE" showPhoto />
        <ScrollDown color="WHITE" />
      </Front>

      <FrontFullWidth bgcolor="#fff">
        <div style={{ margin: "2em 0" }}>
          <h1>Innlegg på Medium</h1>
          <p>
            Greier med IT-relatert innhold. Disse kan være postet i diverse
            medium-publikasjoner.
          </p>
        </div>
        {data?.getMediumPosts?.map((item: any, index: number) => {
          return (
            <MediumPost
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              key={`${item.title}_${index}`}
            >
              <MediumImg src={item.img[0]} />
              <MediumPostMeta>
                <h2>{item.title}</h2>
                <div>{format(new Date(item.pubDate), "dd.MM.yyyy HH.mm")}</div>
                <span className="fake-link">Les på Medium...</span>
              </MediumPostMeta>
            </MediumPost>
          );
        })}
      </FrontFullWidth>
      <FrontFullWidth bgcolor="#313131" className="white-text">
        <div style={{ margin: "2em 0" }}>
          <h1>Siste bilder</h1>
          <p>Noen av bildene jeg tar dukker opp her</p>
          <ImageGrid>
            {lastNine.map((img, i) => (
              <Img
                key={img.id}
                square
                src={`https://img.wedin.no/api/images/${img.id}?width=400px`}
                largeSrc={`https://img.wedin.no/api/images/${img.id}?width=1600px`}
              />
            ))}
          </ImageGrid>
        </div>
      </FrontFullWidth>
      <FrontFullWidth>
        <div style={{ margin: "2em 0" }}>
          <h1>Kontakt</h1>
          <p>
            Dersom du ønsker å kontakte meg kan du for øyeblikket prøve på
            LinkedIn
          </p>
        </div>
      </FrontFullWidth>

      <Footer />
    </>
  );
};

const Front = styled(animated.div)`
  grid-template-rows: repeat(6, 1fr);
  background-color: #000;
  min-height: 100dvh;
  position: relative;
  grid-column: 1 / 7;
  z-index: 1;
  display: grid;
  overflow: hidden;
  border: 10px solid #fff;
  border-bottom: none;
  img,
  ul {
    align-self: center;
    font-weight: 100;
  }

  /*
  img {
    width: 80%;
    padding: 0 1em;
    margin: 0 auto;
    grid-column: 2 / 7;
    grid-row: 2 / 5;
    align-self: normal;
    max-width: 500px;
    @media (min-width: 800px) {
      width: 35vw;
      margin: unset;
    }
  }
  */

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-repeat: no-repeat;
    right: 0;
    z-index: -1;
    background-position: center;
    background-size: cover;
    opacity: 0.6;
    background-image: url(https://img.wedin.no/api/images/55?width=1600);
  }

  .social {
    align-self: center;
  }
`;

const LatestPostsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  grid-gap: 0px;

  a {
    display: block;
    text-decoration: none;
    max-width: 400px;
    &:hover {
      .fake-link {
        color: #666;
        text-decoration: underline;
      }
    }
  }
  div {
    min-width: 30.3%;
  }
  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  h2 {
    margin: 5px 0 10px;
  }
  p {
    font-size: 0.9em;
    margin: 0.2em 0 1em;
  }

  @media (min-width: 800px) {
    grid-template-columns: repeat(auto-fill, minmax(30.3%, 1fr));
    grid-gap: 1em;

    h2 {
      margin: 5px 0 5px;
    }
  }
`;

const PostComponent = styled.div`
  margin-bottom: 2em;
  &:last-child {
    margin: 0;
  }
  @media (min-width: 800px) {
    margin: 0;
  }
  a {
    border: none;
  }
`;

const MediumPost = styled.a`
  width: 100%;
  margin: 0 0 2em;
  display: flex;
  flex-wrap: wrap;
  text-decoration: none;
  color: inherit;
  border: none;
  &:hover {
    .fake-link {
      color: #666;
      text-decoration: underline;
    }
  }

  @media (min-width: 800px) {
    flex-wrap: nowrap;
  }
`;

const MediumImg = styled.div<{ src: string }>`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 200px;
  border: 1px solid #efefef;
  @media (min-width: 800px) {
    width: 300px;
  }
`;

const MediumPostMeta = styled.div`
  width: 100%;
  padding: 1em 0;

  h2 {
    margin: 0 0 5px;
  }

  @media (min-width: 800px) {
    width: 70%;
    padding: 0 1em;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 5px;

  @media (min-width: 800px) {
    grid-gap: 10px;
  }
  > img {
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: 800px) {
      //height: 300px;
    }
  }
`;

const Photo = styled.div<{ src: string }>`
  height: 80vw;
  width: 80vw;
  max-width: 150px;
  max-height: 150px;
  align-self: center;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0 5px 20px rgb(0 0 0 / 30%);
  border: 3px solid #fff;
  border-radius: 100%;
  margin-bottom: 2em;
  @media (min-width: 800px) {
    height: 30vw;
    width: 30vw;
  }
`;

export default Frontpage;
