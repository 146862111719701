import { defaultCoreCipherList } from 'constants';
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface HelmetProps {
    title: string;
    description?: string;
    image?: string;
}

const HelmetConfig = (props: HelmetProps) => {
    return (
        <Helmet titleTemplate={`%s | Glenn Wedin - IT og naturfoto`}>
            {props.description && (
                <meta name="description" content={props.description} />
            )}
            {props.image && <meta property="og:image" content={props.image} />}
            <title>{props.title}</title>
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Organization',
                    url: 'https://glenn.wedin.no',
                    logo: 'https://glenn.wedin.no/static/logo.png',
                    author: {
                        '@type': 'Person',
                        name: 'Glenn Wedin',
                        hasOccupation: 'IT-consultant',
                    },
                })}
            </script>
        </Helmet>
    );
};

export default HelmetConfig;
