import { useEffect } from "react";
import { useState } from "react";
import fetch from "isomorphic-unfetch";

interface Image {
  active: number;
  categories_id: number;
  category: string;
  created: string;
  filename: string;
  id: number;
  public: number;
}

export const useLatestImages = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch("https://img.wedin.no/api/images");
        const json = await response.json();
        setImages(json);
      } catch (e) {
        // do nothing if no result
        console.error(e);
      }
    })();
  }, []);

  const getLatestN = (n: number): Image[] => {
    const imageList = images.slice(0, n);
    return imageList;
  };

  return { images, getLatestN };
};
