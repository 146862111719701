import React from 'react';
import { FooterWrap } from './styled';

export const Footer = () => {
    return (
        <FooterWrap color="#fff">
            &copy; Glenn Wedin {new Date().getFullYear()}
        </FooterWrap>
    );
};
