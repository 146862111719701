import React from 'react';
import styled from 'styled-components';

export const ScrollDown = (props: { color?: 'WHITE' | 'BLACK' }) => {
    return (
        <Arrow color={props.color}>
            <span></span>
            <span></span>
            <span></span>
        </Arrow>
    );
};

const Arrow = styled.div<{ color?: 'WHITE' | 'BLACK' }>`
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, -50%);
    span {
        display: block;
        width: 15px;
        height: 15px;
        border-bottom: 2px solid
            ${(props) => (props.color ? props.color : '#FFF')};
        border-right: 2px solid
            ${(props) => (props.color ? props.color : '#FFF')};
        transform: rotate(45deg);
        margin: -10px;
        animation: animate 2s infinite;
    }
    span:nth-child(2) {
        animation-delay: -0.2s;
    }
    span:nth-child(3) {
        animation-delay: -0.4s;
    }
    @keyframes animate {
        0% {
            opacity: 0;
            transform: rotate(45deg) translate(-20px, -20px);
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0;
            transform: rotate(45deg) translate(20px, 20px);
        }
    }
`;
