import React from 'react';
import styled from 'styled-components';

export const FrontFullWidth: React.FC<{
  className?: string;
  children: any;
  bgcolor?: string;
}> = (props) => {
  return (
    <FrontFullWidthWrap className={props.className} bgcolor={props.bgcolor}>
      <Grid>{props.children}</Grid>
    </FrontFullWidthWrap>
  );
};

const FrontFullWidthWrap = styled.div<{ bgcolor?: string }>`
  grid-column: 1 / 7;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 4em 0 8em;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : '#f8f8f8')};

  &.white-text {
    h1,
    h2,
    h3,
    p {
      color: #fff;
    }
  }
`;

const Grid = styled.div`
  grid-column: 2 / 6;
  display: grid;
`;
